.nav-link {
    display: inline;
    padding: .5rem 1rem;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Flag-center {
  text-align: center;
  padding: 3px 3px;
  margin-left: 15px;
  margin-right: 10px;
}

.Basic-page-layout {
  margin-left: 120px;
  margin-right: 120px;
}

.alert {
    position: relative;
    padding: .5rem 1.25rem;
    margin-bottom: -15px;
}

.alert-dark {
  color: white;
  background-color: black;
  border-color: black;
  text-align: center;
  margin-bottom: -20px;
  padding-top: 5px;
  font-size: small;
  border-radius: 0;
}

.nav-item:hover .dropdown-menu{
  display:block!important
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: .05rem 1.25rem;
  color: inherit;
}

.navbar-expand-lg .navbar-nav .nav-link {
padding: 0px
}

.NavbarLink {
  padding: 9.3px 13px;
  color: white;
}

.Mead-Quote {
  text-align: center;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-style: italic;
  padding: 3%;
}

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.centeredPage {
  max-width: 1300px;
}

.leftAlignedPage {
  text-align: -webkit-left;
  padding: 8%;
}

.Site-footer {
  flex: none; /* 2 */
  width: 100%;
  position: fixed;
  bottom: 0;
}

:root {
    --space: 1.5em 0;
    --space: 2em 0;
}

html, body, #root, #root>div {
  height: 100%;
  min-width: 900px
}

.Site-content {
  text-align: -webkit-center;
  flex: 1 0 auto;
  padding: var(--space) var(--space) 0;
  width: 100%;
}
.Site-content::after {
  content: '\00a0';
  display: block;
  margin-top: var(--space);
  height: 0;
  visibility: hidden;
}
@media (--break-lg) {
  .Site-content {
    padding-top: var(--space-lg);
  }
  .Site-content::after {
    margin-top: var(--space-lg);
  }
}

.Site-content--full {
  padding: 0;
}
.Site-content--full::after {
  content: none;
}

/* html,
body {
  height: 100%;
  margin: 10%;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row {
  border: 1px dotted grey;
  margin: 0px;
} */

/* .box .row.header {
  flex: 0 1 auto;
  // The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto

} */

/* .box .row.content {
  flex: 1 1 auto;
} */

/* Or: */
/* .box .row.content {
  display: flex;
  flex-flow: column;
  flex: 0 1 auto;
  overflow-y: auto;
}

.box .row.footer {
  flex: 1 1 40px;
  margin: 0px;
} */
